import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import SeeMore from "../components/SeeMore";
import CardNews from "../components/Cards/CardNews";
import Title from "../components/Title";

import "../utils/general.scss";

const News = ({ pageContext }) => {
    let [limit, setLimit] = useState(10);

    const data = useStaticQuery(graphql`
    {
        allNews(filter: {metadatas: {type: {eq: "news"}}}, sort: {fields: publishedAt___date, order: DESC}) {
            edges {
                node {
                    id
                    name
                    image
                    banner
                    publishedAt {
                        date(formatString: "DD/MM/YYYY")
                    }
                    metadatas {
                        name_eng
                    }
                }
            }
        }
    }
    `);

    const news = data.allNews.edges.map((item, index) => {
        if (index >= limit) return;
        item = item.node;
        
        return (
            <CardNews
                key={index}
                to={`news/${item.id}`}
                date={item.publishedAt.date}
                title={(typeof window !== 'undefined' && window.localStorage.language === "en" && item.metadatas && item.metadatas.name_eng) ? item.metadatas.name_eng : item.name}
                image={item.image ? item.image : item.banner}
            />
        )
    });

    return (
        <Layout pageContext={pageContext}>
            <SEO title="News">
                {typeof window !== 'undefined' &&
                    <link href={window.location} rel="canonical" />
                }
            </SEO>

            <section className="section-news colorful-bg">
                <div className="container section">
                    <Title title="News" />

                    { news }

                    {data.allNews.edges.length > limit &&
                        <SeeMore text={pageContext.localeResources['cta.seeMore']} onClick={() => setLimit(limit + 10)} />
                    }
                </div>
            </section>
        </Layout>
    )
}

export default News

import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import language_config from "../../../../language-config";

import "../style.scss";
import "./style.scss";

const CardNews = ({to, title, date, image}) => {
    let [path, setPath] = useState('/');

    useEffect(() => {
        let newPath = ''
        if (typeof window !== 'undefined') {
            newPath = ((language_config[window.localStorage.language].is_default ? '' : ('/' + window.localStorage.language)) + '/')
        } else {
            newPath = '/'
        }
        setPath(newPath)
    }, []);


    return (
        <Link className="card card-news rounded ie" to={`${path}${to}`}>
            <div className="cardImage rounded" style={{backgroundImage: `url(${image})`}}></div>
            <div className="cardInfo">
                <span className="subtitle">{date}</span>
                <h5>{title}</h5>
            </div>
        </Link>
    )
}

export default CardNews
